<template lang="pug">
v-form(:disabled='!editMode').userEditor
    v-row
        v-col
            v-text-field(label='Username', outlined, dense, hide-details, color='brand', disabled, v-model='user.username')
    v-row
        v-col
            v-text-field(label='First Name', outlined, dense, hide-details, color='brand', v-model='user.firstName')
        v-col
            v-text-field(label='Last Name', outlined, dense, hide-details, color='brand', v-model='user.lastName')
    v-row
        v-col
            v-text-field(label='Email', outlined, dense, hide-details, color='brand', v-model='user.email')
        v-col
            v-text-field(label='Phone Number', outlined, dense, hide-details, color='brand', v-model='user.phoneNumber')
    v-row(v-if='allowRoleEdit')
        v-col
            v-select(label='Role', outlined, dense, hide-detailes, color='brand', v-model='siteSettings.role', :items='roleOptions')
    v-row
        v-col
            v-btn(text, @click='showPasswordReset = true') Reset Password
    v-row(v-if='showPasswordReset')
        v-col.d-flex
            v-text-field(color='brand', label='New Password', type='password', outlined, dense, v-model='newPassword', :rules='passwordRules', :disabled='false')
            v-btn(icon, @click='resetPassword', title='Save New Password')
                v-icon lock_reset
    template(v-if='customerId > 0')
        v-row
            v-col
                v-list
                    v-subheader Notifications
                    v-list-item(two-line)
                        v-list-item-action
                            v-checkbox(color='brand', v-model='siteSettings.enableNotifications')
                        v-list-item-content
                            v-list-item-title Receive Notifications
                            v-list-item-subtitle Enable email notifications from Dorsett Controls
                    template(v-if='siteSettings.enableNotifications')
                        v-divider
                        v-list-item(three-line)
                            v-list-item-action
                                v-checkbox(color='brand', v-model='siteSettings.infoScanUpdateNotifications')
                            v-list-item-content
                                v-list-item-title InfoScan Updates
                                v-list-item-subtitle Get notified when a new InfoScan version is available for download
                        v-list-item(two-line)
                            v-list-item-action
                                v-checkbox(color='brand', v-model='siteSettings.generalNotifications')
                            v-list-item-content
                                v-list-item-title General
                                v-list-item-subtitle General communications from Dorsett Controls
        v-row(v-if='userHasMultipleCustomers')
            v-col
                v-icon(small, left) info
                span These notification settings only apply for this site ({{ currentSite.name }}). You may still receive notifications regarding other sites. You can select a different site by using the dropdown at the top of the screen.
    v-row
        v-col.d-flex
            v-spacer
            slot(name='card-actions')
            v-btn.white--text(color='brand', @click='saveUser', :loading='saving', v-if='editMode') Save

</template>
<script>
export default {
    props: {
        username: {
            type: String,
            default: ''
        },
        customerId: {
            type: Number,
            default () {
                return -1;
            }
        },
        allowRoleEdit: {
            type: Boolean,
            default () {
                return false;
            }
        },
        editMode: {
            type: Boolean,
            default () {
                return true;
            }
        }
    },
    data () {
        return {
            user: {},
            showPasswordReset: false,
            newPassword: '',
            saving: false,
            passwordRules: [
                value => !value || /^((?=.*[a-z])(?=.*[A-Z])(?=.*\d)|(?=.*[a-z])(?=.*[A-Z])(?=.*[^A-Za-z0-9])|(?=.*[a-z])(?=.*\d)(?=.*[^A-Za-z0-9])|(?=.*[A-Z])(?=.*\d)(?=.*[^A-Za-z0-9]))([A-Za-z\d@#$%^&£*\-_+=[\]{}|\\:',?/`~"();!]|\.(?!@)){8,}$/.test(value) || 'Invalid Password.'
            ],
            roleOptions: ['admin', 'member']
        };
    },
    computed: {
        userHasMultipleCustomers () {
            return this.user?.customers?.length > 1;
        },
        siteSettings () {
            const customerData = this.user?.customers?.find(customer => customer.id === this.customerId);
            if (customerData) {
                return customerData.customersCustomerUsers;
            }
            return {};
        },
        currentSite () {
            return this.$store.state.customerData || this.user?.customers?.find(customer => customer.id === this.customerId) || {};
        }
    },
    methods: {
        async getUser () {
            const result = await this.post('getUser', {
                username: this.username
            });
            this.user = result;
        },
        async saveUser () {
            this.saving = true;
            const result = await this.post('saveUser', {
                user: this.user
            });
            if (result) {
                this.showPasswordReset = false;
                this.$emit('userUpdated');
            }
            this.saving = false;
        },
        async resetPassword () {
            const result = await this.post('resetUserPassword', {
                userId: this.user.id,
                password: this.newPassword
            });
            if (result.err) {
                this.message = 'Error resetting password';
            } else {
                this.showPasswordReset = false;
                this.$emit('userUpdated');
            }
        }
    },
    watch: {
        username: {
            immediate: true,
            handler () {
                this.getUser();
            }
        }
    },
    mounted () {
        window.usereditorvm = this;
    }
};
</script>
